<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link to="/data-opname" class="nav-link"
                  >Perintah Opname</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-stock" class="nav-link active"
                  >Hasil Stok Opname</router-link
                >
              </li>
            </ul>
          </div>

          <form class="search mr-3" action="/action_page.php">
            <input
              type="text"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>

          <router-link to="/data-stock/tambah" class="btn-add"
            >+ Tambah
          </router-link>
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center">
            <Select2
              v-model="Date"
              :options="OptionDate"
              placeholder="Pilih Tanggal"
              class="mr-3"
              style="width: 200px"
              :settings="{ settingOption: value, settingOption: value }"
              @change="myChangeEvent($event)"
              @select="mySelectEvent($event)"
            />
          </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableDepartment"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Tanggal</th>
                  <th style="width: 15%">Nomor</th>
                  <th style="width: 15%">Nomor Perintah</th>
                  <th style="width: 25%">Keterangan</th>
                  <th style="width: 10%">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>31 Jan 2022</td>
                  <td>OPO.00003</td>
                  <td>OPO.00003</td>
                  <td>Dalam pengecekan stock barang</td>
                  <td>
                    <div class="green-bedge">Aktif</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>31 Jan 2022</td>
                  <td>OPO.00003</td>
                  <td>OPO.00003</td>
                  <td>Dalam pengecekan stock barang</td>
                  <td>
                    <div class="green-bedge">Aktif</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>31 Jan 2022</td>
                  <td>OPO.00003</td>
                  <td>OPO.00003</td>
                  <td>Dalam pengecekan stock barang</td>
                  <td>
                    <div class="green-bedge">Aktif</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>31 Jan 2022</td>
                  <td>OPO.00003</td>
                  <td>OPO.00003</td>
                  <td>Dalam pengecekan stock barang</td>
                  <td>
                    <div class="red-bedge">Selesai</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    Select2,
  },

  mounted() {
    $("#tableDepartment").DataTable({
      info: false,
    });

    this.tooltip();
  },

  methods: {
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail() {
      this.$router.push("/data-stock/detail");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}
</style>

<style scoped src="../../../../../assets/css/style.css"></style>

<style scoped src="../../../../../assets/css/custom.css"></style>
